import { createRouter, createWebHashHistory } from "vue-router";
import Layout from "@/components/layOut/index.vue";
import SubLayout from "@/components/subLayOut/index.vue";
const routes = [
  {
    path: "/",
    redirect: "/login",
    component: Layout,
    children: [
      {
        path: "/enterprise",
        component: SubLayout,
        children: [
          {
            path: "/enterprise",
            name: "企业控制台",
            component: () => import("../views/enterprise/Enterprise.vue"),
          },
          {
            path: "/enterprise/yinzhang",
            name: "印章管理",
            component: () => import("../views/enterprise/Yinzhang.vue"),
          },
          {
            path: "/enterprise/chengyuan",
            name: "成员管理",
            component: () => import("../views/enterprise/Chengyuan.vue"),
          },
          {
            path: "/enterprise/zhanghu",
            name: "企业账户",
            component: () => import("../views/enterprise/Zhanghu.vue"),
          },
          {
            path: "/enterprise/renzhneg",
            name: "企业认证",
            component: () => import("../views/enterprise/Renzheng.vue"),
          },
          {
            path: "/enterprise/juese",
            name: "角色管理",
            component: () => import("../views/enterprise/Juese.vue"),
          },
          {
            path: "/enterprise/regulatoryCapital",
            name: "regulatoryCapital",
            component: () => import("@/views/enterprise/accountManagement.vue"),
            meta: {
              title: "资金监管账户管理",
              manager: true,
            },
          },
          {
            path: "/enterprise/disputeProcessing",
            name: "disputeProcessing",
            component: () => import("@/views/enterprise/accountManagement.vue"),
            meta: {
              title: "纠纷处理账户管理",
              manager: true,
            },
          },
          {
            path: "/enterprise/logs",
            name: "logs",
            component: () => import("@/views/enterprise/logs.vue"),
            meta: {
              title: "日志",
              manager: true,
            },
          },
          {
            path: "/serialNumber",
            name: "serialNumber",
            component: () => import("@/views/enterprise/serialNumber.vue"),
            meta: {
              title: "序号类别",
              manager: true,
            },
          },
        ],
      },
      {
        path: "/commonTemplate",
        name: "commonTemplate",
        component: () => import("../views/commonTemplate/commonTemplate"),
        meta: {
          title: "公用模板",
          manager: true,
        },
      },
      {
        path: "/home",
        name: "Home",
        component: () => import("../views/Home.vue"),
      },
      {
        path: "/details",
        name: "合同管理",
        component: () => import("../views/details/Details.vue"),
      },
      {
        path: "/userinfo",
        name: "用户中心",
        component: () => import("../views/userinfo/UserInfo.vue"),
      },
      {
        path: "/details/daishen/:id",
        name: "详情",
        component: () => import("../views/xiangqing/Daishen.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/Register.vue"),
  },
  {
    path: "/forgetpassword",
    name: "ForgetPassword",
    component: () => import("../views/ForgetPassword.vue"),
  },
  {
    path: "/contracttemplate/:id",
    name: "ContractTemplate",
    component: () => import("../views/ContractTemplate.vue"),
  },
  {
    path: "/details/template",
    name: "选择模板",
    component: () => import("../views/initiatingContract/Template.vue"),
  },
  {
    path: "/details/sign",
    name: "指定签署位置",
    component: () => import("../views/initiatingContract/Sign.vue"),
  },
  {
    path: "/details/configuration/:id",
    name: "配置签约方",
    component: () => import("../views/initiatingContract/Configuration.vue"),
  },
  {
    path: "/enterprise/list/:id",
    name: "文件列表",
    component: () => import("../views/qiye/List.vue"),
  },
  {
    path: "/mb/FileList/:id",
    name: "修改文件列表",
    component: () => import("../views/mb/FileList.vue"),
  },
  {
    path: "/enterprise/contractobject/:id",
    name: "签约对象模板",
    component: () => import("../views/qiye/ContractObject.vue"),
  },
  {
    path: "/SigningTools/SignContract/:id",
    name: "开始签约",
    component: () => import("../views/SigningTools/SignContract.vue"),
  },
  {
    path: "/template/contractmoban/:id",
    name: "签约对象",
    component: () => import("../views/mb/ContractMoban.vue"),
  },
  {
    path: "/SigningTools/index",
    name: "签约对象1",
    components: () => import("../views/SigningTools/index.vue"),
  },
  {
    path: "/SigningTools/preview/:id",
    name: "预览合同",
    component: () => import("../views/SigningTools/preview.vue"),
  },
  {
    path: "/public/BmsIndex/:key",
    name: "Bms首页",
    component: () => import("../views/public/BmsIndex.vue"),
  },
  {
    // 新
    path: "/public/bmsContractNew/:encryption/:token",
    name: "bmsContract",
    component: () => import("../views/public/BmsContract-new.vue"),
  },
  {
    //老
    path:
      "/public/bmsContract/:id/:jobId/:postId/:deptId/:mid/:sourceId/:fid/:relationFid/:token",
    name: "Bms合同",
    component: () => import("../views/public/BmsContract.vue"),
  },
  {
    path: "/public/preview/:id",
    name: "Bms预览合同",
    component: () => import("../views/public/BmsPreview.vue"),
  },
  {
    //新
    path: "/public/bmsContractPdfNew/:passId/:token",
    name: "BmsPdfContract-new",
    component: () => import("../views/public/BmsPdfContract-new.vue"),
  },
  {
    //旧
    path: "/public/BmsPdfContract/:id/:isPrint/:token",
    name: "Bms合同开始",
    component: () => import("../views/public/BmsPdfContract.vue"),
  },
  // 旧
  {
    path: "/public/BmsPdfTemplate/:id/:watermark/:token",
    name: "BmsPdfTemplate",
    component: () => import("../views/public/BmsPdfTemplate.vue"),
  },
  // 新
  {
    path: "/public/BmsPdfTemplateNew/:pass/:token",
    name: "BmsPdfTemplate-new",
    component: () => import("../views/public/BmsPdfTemplate-new.vue"),
  },
  {
    path: "/public/moveBmsContract/:key",
    name: "Bms手机合同",
    component: () => import("../views/public/moveBmsContract.vue"),
  },
  {
    path: "/public/moveEleContract/:id/:jobId/:deptId/:postId/:partyBName/:idCard/:oatype/:exitTime?",
    name: "moveEleContract",
    component: () => import("../views/public/moveEleContract.vue"),
    beforeEnter: (to, from, next) => {
      if (!to.params.exitTime) {
        next(); // 继续执行路由   
      } else {
        next(); // 如果exitTime有值，继续执行路由
      }
    }
  },
  {
    //公寓
    path: "/public/contract/:token",
    name: "contract",
    component: () => import("../views/public/contract/contract.vue"),
  },
  {
    path: "/public/moveContract/:token",
    name: "moveContract",
    component: () => import("../views/public/contract/moveContract.vue"),
  },
  {
    path: "/public/pdfPreview/:token",
    name: "pdfPreview",
    component: () => import("../views/public/contract/pdfPreview.vue"),
  },
  {
    //公寓预览pdf
    path: "/public/pdfContract/:token",
    name: "pdfContract",
    component: () => import("../views/public/pdfContract/pdfContract.vue"),
  },
  {
    path: "/test/:id/:isPrint/:token",
    name: "test",
    component: () => import("../views/test.vue"),
  },
  {
    path: '/VisualEditor',
    name: "VisualEditor",
    component: () => import("../views/ceshi/index.vue"),
  }
];
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
