import request from "@/utils/request";

export default {
  // 登录
  login(data) {
    return request({
      url: process.env.VUE_APP_URL + "login/index",
      method: "post",
      data,
    });
  },
  // 注册(要携带短信id,生成验证码会生成id)
  register(data) {
    return request({
      url: "http://htxsqj.xhj.com/blade-asa-regist/regist/save_cn_user_code",
      method: "post",
      data,
    });
  },
  // 验证码(携带phone参数)
  verification(data) {
    return request({
      url: "http://htxsqj.xhj.com/blade-asa-regist/regist/sendsms",
      method: "get",
      params: data,
    });
  },
  //
  loginOut(data) {
    return request({
      url: process.env.VUE_APP_URL + "login/loginOut",
      method: "post",
      data,
    });
  },
};
