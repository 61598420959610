import request from "@/utils/request";

export default {
  // 个人公司列表
  postSigningList(data) {
    // console.log(data);
    return request({
      url: process.env.VUE_APP_URL + "signing/SigningList",
      method: "post",
      data,
    });
  },
  postSigningID(data) {
    return request({
      url: process.env.VUE_APP_URL + "user/postSigningID",
      method: "post",
      data,
    });
  },
  postId(data) {
    return request({
      url: process.env.VUE_APP_URL + "sign/id",
      method: "post",
      data,
    });
  },
  postIdKey(data) {
    return request({
      url: process.env.VUE_APP_URL + "sign/idKey",
      method: "post",
      data,
    });
  },
  postIdHTTP(data) {
    return request({
      url: process.env.VUE_APP_HTTP + "sign/id",
      method: "post",
      data,
    });
  },
  contractId(data) {
    return request({
      url: process.env.VUE_APP_URL + "sign/contractId",
      method: "post",
      data,
    });
  },
  postSave(data) {
    return request({
      url: process.env.VUE_APP_URL + "pdf/preserve",
      method: "post",
      data,
    });
  },
  postSign(data) {
    return request({
      url: process.env.VUE_APP_URL + "sign/postSign",
      method: "post",
      data,
    });
  },
  postSignContractApp(data) {
    return request({
      url: process.env.VUE_APP_URL + "sign/postSignContractApp",
      method: "post",
      data,
    });
  },
  postSignApp(data) {
    return request({
      url: process.env.VUE_APP_URL + "sign/postSignApp",
      method: "post",
      data,
    });
  },
  revoke(data) {
    return request({
      url: process.env.VUE_APP_URL + "sign/revoke",
      method: "post",
      data,
    });
  },
  likePhone(data) {
    return request({
      url: process.env.VUE_APP_URL + "sign/likePhone",
      method: "post",
      data,
    });
  },
  // h5跳手机端
  postContract(data) {
    return request({
      url: `${process.env.VUE_APP_URL}EmploymentContract/postContract`,
      method: "post",
      data,
    });
  },
};
