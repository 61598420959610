
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/scss/index.scss'
import 'element-plus/lib/theme-chalk/index.css';
// import Vant from 'vant';
// import 'vant/lib/index.css';
import '@/utils/flexible.js'
import '@/assets/scss/common.scss'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
import ElementPlus from './plugins/element';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus';
import directives from '@/directives/index'
import { createPinia } from 'pinia';
const app = createApp(App)
app.use(createPinia());
app.config.globalProperties.$message = ElMessage
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
Object.keys(directives).forEach(el => {
    app.directive(el, directives[el])
})
// app.use(Vant);
app.use(ElementPlus)
app.use(router).mount('#app')
