// // 按需加载
// import {
//   ElTabs, ElInput, ElForm, ElTabPane, ElFormItem, ElCheckbox, ElCheckboxGroup, ElButton, ElMessage, ElPageHeader, ElContainer, ElHeader,
//   ElAside, ElMain, ElFooter, ElMenu, ElSubmenu, ElMenuItem, ElImage, ElRow, ElCol, ElBreadcrumb, ElBreadcrumbItem, ElTable, ElTableColumn, ElPagination,
//   ElMenuItemGroup, ElDatePicker, ElSelect, ElOption, ElSwitch, ElUpload, ElPopconfirm, ElDialog, ElSelectV2, ElImageViewer, ElDropdown, ElDropdownItem, ElDropdownMenu,
//   ElStep, ElSteps, ElRadio, ElRadioGroup
// } from 'element-plus';
// const plugins = [ElTabs, ElInput, ElForm, ElTabPane, ElFormItem, ElCheckbox, ElCheckboxGroup, ElButton, ElMessage, ElPageHeader, ElContainer,
//   ElHeader, ElAside, ElMain, ElFooter, ElMenu, ElSubmenu, ElMenuItem, ElImage, ElRow, ElCol, ElBreadcrumb, ElBreadcrumbItem, ElTable, ElTableColumn,
//   ElPagination, ElMenuItemGroup, ElDatePicker, ElSelect, ElOption, ElSwitch, ElUpload, ElPopconfirm, ElDialog, ElSelectV2, ElImageViewer,
//   ElDropdown, ElDropdownItem, ElDropdownMenu, ElStep, ElSteps, ElRadio, ElRadioGroup]

// // import lang from 'element-plus/lib/locale/lang/zh-cn'//国际化
// // locale(lang)//国际化

// const ElementPlus = {
//   install: (app) => {
//     plugins.forEach(item => {
//       app.component(item.name, item);
//     })
//   }
// }
// export default ElementPlus



// 全局引入
import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
import locale from 'element-plus/lib/locale/lang/zh-cn'
export default (app) => {
  app.use(ElementPlus, { locale })
}
