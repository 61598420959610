import request from "@/utils/request";
export default {
  //个人信息
  userInfo(data) {
    return request({
      url: process.env.VUE_APP_URL + "user/user",
      method: "post",
      data,
    });
  },
  // 个人公司列表
  template(data) {
    return request({
      url: process.env.VUE_APP_URL + "sign/SignList",
      method: "post",
      data,
    });
  },
  // 上传图片
  uploadseal(data) {
    return request({
      url: "http://htxsq.xhj.com/api/seal/upload",
      method: "post",
      data,
    });
  },
  // 印章列表
  sealmanage(data) {
    return request({
      url: process.env.VUE_APP_URL + "seal/list",
      method: "post",
      data,
    });
  },
  // 新增印章
  addsealmanage(data) {
    return request({
      url: process.env.VUE_APP_URL + "seal/addseal",
      method: "post",
      data,
    });
  },
  //删除模板
  delect(data) {
    return request({
      url: process.env.VUE_APP_URL + "signing/delect",
      method: "post",
      data,
    });
  },
  searchData(data) {
    return request({
      url: process.env.VUE_APP_URL + "sign/searchData",
      method: "post",
      data,
    });
  },
  // 同步按钮
  SynchronousTemplate(data) {
    return request({
      url: process.env.VUE_APP_URL + "CommonTemplate/SynchronousTemplate",
      method: "post",
      data,
    });
  },
};
