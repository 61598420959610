// 引入cookies
import cookies from './cookies'

export default {
    // 获取key
    getItem(key, defaultValue = '') {
        // 获取cookie
        const value = cookies.getCookie(key)
        return value
            ? atob(decodeURIComponent(value))
            : localStorage.getItem(key) || defaultValue
    },
    // 设置
    setItem(key, value, expiresAfterDays = 7) {
        if (!value) {
            cookies.removeCookie(key)
            localStorage.removeItem(key)
            return
        }
        cookies.setCookie(key, btoa(value), expiresAfterDays)
        localStorage.setItem(key, value)
    },
    // 删除
    removeItem(key) {
        cookies.removeCookie(key)
        localStorage.removeItem(key)
    }
}