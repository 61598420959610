import _defineProperty from "E:/web/xhqyVue/node_modules/@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.function.name.js";
import { ElConfigProvider } from 'element-plus'; // 中文包

import zhCn from 'element-plus/lib/locale/lang/zh-cn';
export default {
  components: _defineProperty({}, ElConfigProvider.name, ElConfigProvider),
  setup: function setup() {
    // 切换为中文
    var locale = zhCn;
    return {
      locale: locale
    };
  }
};