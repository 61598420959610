import login from "@/api/login";
import signingBll from "@/api/signing";
import cache from "@/utils/cache";
export default {
  setup: function setup() {
    return {};
  },
  data: function data() {
    return {
      postSigningList: [],
      // value: '',
      user: "",
      userInfo: []
    };
  },
  mounted: function mounted() {
    var _this = this;

    var user = cache.getItem("user");
    var userInfoIndex = cache.getItem("userInfoIndex");

    if (user) {
      user = JSON.parse(user);
      this.user = user;
      signingBll.postSigningList({
        uid: user["id"]
      }).then(function (res) {
        // 获取列表数据
        _this.postSigningList = res;

        if (!userInfoIndex) {
          _this.userInfo = res[0];
          cache.setItem("userInfo", encodeURI(JSON.stringify(_this.postSigningList[0])));
        } else {
          _this.userInfo = res[userInfoIndex];
        }
      });
    } // let userInfo = decodeURIComponent(cache.getItem('userInfo'))
    // if (userInfo) {
    //   this.userInfo = JSON.parse(userInfo)
    // }

  },
  methods: {
    handleSelect: function handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    to: function to() {
      this.$router.push("/home");
    },
    quit: function quit() {
      try {
        login.loginOut({
          uid: localStorage.getItem("uid")
        });
      } catch (err) {
        console.log(err);
      }

      localStorage.clear();
      cache.removeItem("userInfo");
      cache.removeItem("user");
      cache.removeItem("userInfoIndex"); // window.sessionStorage.clear()

      this.$router.push("/login");
    },
    clickBtn: function clickBtn(index) {
      // console.log(index)
      this.userInfo = this.postSigningList[index];
      cache.setItem("userInfo", encodeURI(JSON.stringify(this.postSigningList[index])));
      cache.setItem("userInfoIndex", index);
      window.location.reload(); // console.log(this.postSigningList[index]);
    }
  }
};