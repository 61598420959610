export default {
    created(el, binding) {
        let timer
        el.addEventListener('click', () => {
            if (!timer || Date.now() - timer >= 2000) {
                timer = Date.now()
                binding.value()
            }
        })
    }
}
