import { useRoute } from "vue-router";
import temp from "@/api/template";
import cache from "@/utils/cache";
import { defineComponent, reactive, ref, watch } from "vue";
export default defineComponent({
  data: function data() {
    return {
      pageIndex: 1,
      user: "",
      tableData: []
    };
  },
  props: {
    tabIndex: {
      default: 0
    }
  },
  emits: ["tabIndex"],
  setup: function setup(props, tab) {
    var data = reactive({
      navTitle: ""
    });
    var Route = useRoute();
    var curTabIndex = ref(props.tabIndex);

    var tabClick = function tabClick(ind) {
      curTabIndex.value = ind;
      tab.emit("tabIndex", curTabIndex.value);
    };

    watch(Route, function () {
      console.log(Route.meta.title, "Route==");
      data.navTitle = Route.meta.title;
    });
    return {
      data: data,
      Route: Route,
      tabClick: tabClick,
      curTabIndex: curTabIndex,
      input: ref("")
    };
  },
  mounted: function mounted() {
    var _this = this;

    var user = cache.getItem("user");

    if (user) {
      user = JSON.parse(user);
      this.user = user;
      console.log(this.user);
      temp.template({
        uid: user["id"],
        page: this.pageIndex
      }).then(function (res) {
        console.log(res);
        _this.tableData = res;
      });
    }
  },
  methods: {
    handleOpen: function handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose: function handleClose(key, keyPath) {
      console.log(key, keyPath);
    }
  }
});